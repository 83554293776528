import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Bridge {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.floorTexture = null
    this.bridgeSides = null
    this.bridge = null

    this.setFloorTexture()
    this.setSidesTexture()
  }

  findBridge() {
    if(!this.bridge) {
      this.bridge = this.scene.children.find((child) => child.name === 'Cylinder345')
    }
  }
  
  setFloorTexture() {
    this.floorTexture = this.resources.items.bridgeTexture
    this.floorTexture.repeat.set(10, 4)
    this.floorTexture.wrapS = this.floorTexture.wrapT = THREE.RepeatWrapping
  }

  setSidesTexture() {
    this.bridgeSides = this.resources.items.bridgeTexture
    this.bridgeSides.wrapS = this.bridgeSides.wrapT = THREE.MirroredRepeatWrapping
    this.bridgeSides.repeat.set(8, .2)
  }

  applyTexture() {
    this.findBridge()
    if(this.bridge) {
      this.bridge.material.map = this.bridge_sides
      const bridge_floor = this.bridge.children[0]
      bridge_floor.material.map = this.floorTexture
    }
  }

  update() {
    this.applyTexture()
  }
}