import * as THREE from 'three'
import Experience from "../Experience.js"

export default class Environment {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.lightIntensity = 0.4

    this.setSunLight()
    this.setEnvironmentMap()
    this.addHemisphereLight()
    this.setAmbientLight()
  }

  setSunLight() {
    this.sunLight = new THREE.PointLight('#BDE3FF', 0.6)
    this.sunLight.position.set(0, 20, 10)
    this.scene.add(this.sunLight)
  }

  addHemisphereLight() {
    const hemisphereLight = new THREE.HemisphereLight('#ffeedd', '#16AB30', 0.5)
    hemisphereLight.position.set(0, 0, 0)
    this.scene.add(hemisphereLight)
  }

  setAmbientLight() {
    const ambientLight = new THREE.AmbientLight('#BDE3FF', 0.3)
    this.scene.add(ambientLight)
  }

  setEnvironmentMap() {
    const children = [...this.resources.items.landformModel.scene.children]
    for (const child of children) {
      this.scene.add(child)
    }
  }
}
