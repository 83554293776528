import * as THREE from 'three'
import Experience from "../Experience.js"
import Environment from './Environment.js'
import Sky from './Sky.js'
import Bridge from './Bridge.js'
import Grass from './Grass.js'
import Stream from './Stream.js'

export default class World {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources

    this.resources.on('ready', () => {
      this.sky = new Sky()
      this.bridge = new Bridge()
      this.grass = new Grass()
      this.stream = new Stream()
      this.environment = new Environment()
    })
  }

  update() {
    if(this.grass){
      this.grass.update()
    }
    if(this.bridge) {
      this.bridge.update()
    }
    if(this.sky) {
      this.sky.update()
    }
    if(this.stream) {
      this.stream.update()
    }
  }
}
