export default [
  {
    name: 'landformModel',
    type: 'gltfModel',
    path: 'models/landform.glb'
  },
  {
    name: 'skyTexture',
    type: 'cubeTexture',
    path: [
      'textures/environmentMaps/SkyMap/px.png',
      'textures/environmentMaps/SkyMap/nx.png',
      'textures/environmentMaps/SkyMap/py.png',
      'textures/environmentMaps/SkyMap/ny.png',
      'textures/environmentMaps/SkyMap/pz.png',
      'textures/environmentMaps/SkyMap/nz.png'
    ]
  },
  {
    name: 'bridgeTexture',
    type: 'texture',
    path: 'textures/bridge/stone-wall-texture.jpg'
  },
  {
    name: 'grassColorTexture',
    type: 'texture',
    path: 'textures/grass/color.jpg'
  },
  {
    name: 'grassAmbientOcclusionTexture',
    type: 'texture',
    path: 'textures/grass/ambientOcclusion.jpg'
  },
  {
    name: 'grassNormalTexture',
    type: 'texture',
    path: 'textures/grass/normal.jpg'
  },
  {
    name: 'grassRoughnessTexture',
    type: 'texture',
    path: 'textures/grass/roughness.jpg'
  },
  {
    name: 'cornModel',
    type: 'gltfModel',
    path: 'models/Corn.glb'
  }
]
