import * as THREE from 'three'
import Experience from "./Experience.js"
import * as TWEEN from './Utils/tween'
import * as dat from 'lil-gui'

export default class Mouse {
  constructor() {

    const displayGUI = true
    /**
     * for testing
     */
    const gui = new dat.GUI({
      width: 400
    })
    /**
     * end
     */

    this.experience = new Experience()
    this.scene = this.experience.scene
    this.camera = this.experience.camera
    this.controls = this.camera.controls
    this.cursorPosition = {x: 0, y: 0}
    this.objectsToTest = []
    this.currentIntersect = null

    this.markers = [
      "Parcel_Marker_1",
      'Parcel_Marker_1002',
      'Parcel_Marker_1008',
      'Parcel_Marker_1010',
      'Parcel_Marker_1012',
      'Parcel_Marker_1014',
      'Parcel_Marker_1004',
      'Parcel_Marker_1020',
      'Parcel_Marker_1016',
      'Parcel_Marker_1018',
    ]

    this.raycaster = new THREE.Raycaster
    this.rayDirection = new THREE.Vector3(-1, -1, -1)
    this.downRay = new THREE.Raycaster
    this.cameraOrigin = {x: -.5, y: 3.1, z: 13.2, rx: -.14, ry: .84, rz: 6.71}
    this.markerMovementLocations = {
      'Parcel_Marker_1': {x: -3.8, y: 3.7, z: .4, rx: -4.81, ry: .4, rz: -3.5},
      'Parcel_Marker_1002': {x: -2.5, y: 2, z: 1.1, rx: -1.88, ry: .08, rz: -4.16},
      'Parcel_Marker_1008': {x: -4.8, y: 2.4, z: 5, rx: -5.79, ry: -0.25, rz: -1.88},
      'Parcel_Marker_1010': {x: -2.5, y: 4.6, z: 3.7, rx: -1.88, ry: -1.55, rz: -1.55},
      'Parcel_Marker_1012': {x: -0.1, y: 2.1, z: 7.4, rx: -3.07, ry: -0.14, rz: 1.49},
      'Parcel_Marker_1014': {x: -3.5, y: 3.7, z: 10.8, rx: -3.18, ry: 0, rz: 4.64},
      'Parcel_Marker_1004': {x: 0.4, y: 3.3, z: 4.6, rx: 2.03, ry: 0.08, rz: -1.23},
      'Parcel_Marker_1020': {x: 2.7, y: 2.7, z: 3.7, rx: 4.97, ry: 0.73, rz: -1.88},
      'Parcel_Marker_1016': {x: -0.5, y: 2.8, z: 7.7, rx: 2.79, ry: 0.19, rz: 2.14},
      'Parcel_Marker_1018': {x: 0.5, y: 2.5, z: 10.3, rx: 2.14, ry: -0.14, rz: 5.08},
    }

    let goTo = {}

    window.addEventListener('click', () => {
      if (this.currentIntersect) {
        let markerName = this.currentIntersect.object.parent.name
        //One target does not have a parent name
        if (markerName == '') {
          markerName = this.currentIntersect.object.name
        }
        goTo = this.markerMovementLocations[markerName]
        this.moveCameraTo(goTo)

        //set selected marker and display dialog box
        document.getElementById("land-parcel-selection-modal-1").setAttribute("data-selected-parcel", markerName)
        document.getElementById("land-selection-modal-link").click()
      }
    })

    window.addEventListener('dblclick', () => {
      if (this.currentIntersect) {
        this.moveCameraTo(this.cameraOrigin)
        return false
      }
    })

    window.addEventListener('mousemove', (event) => {
        this.cursorPosition.x = (event.clientX / window.innerWidth) * 2 - 1
        this.cursorPosition.y = -(event.clientY / window.innerHeight) * 2 + 1
      }
    )

    /**
     * for testing
     */
    this.camParams = {
      cam_x: this.camera.instance.position.x,
      cam_y: this.camera.instance.position.y,
      cam_z: this.camera.instance.position.z,
      cam_rx: this.controls.target.x,
      cam_ry: this.controls.target.y,
      cam_rz: this.controls.target.z
    }
    gui
      .add(this.camParams, 'cam_x')
      .min(-20)
      .max(30)
      .step(0.1)
      .onChange((x) => {
        this.camera.instance.position.x = x
        this.camera.instance.updateWorldMatrix()
      })
    gui
      .add(this.camParams, 'cam_y')
      .min(-20)
      .max(30)
      .step(0.1)
      .onChange((y) => {
        this.camera.instance.position.y = y
        this.camera.instance.updateWorldMatrix()
      })
    gui
      .add(this.camParams, 'cam_z')
      .min(-20)
      .max(30)
      .step(0.1)
      .onChange((z) => {
        this.camera.instance.position.z = z
        this.camera.instance.updateWorldMatrix()
      })

    gui
      .add(this.camParams, 'cam_rx')
      .min(-20)
      .max(30)
      .step(0.1)
      .step(0.01)
      .onChange((x) => {
        this.controls.target.x = x
        this.camera.instance.updateWorldMatrix()
      })
    gui
      .add(this.camParams, 'cam_ry')
      .min(-20)
      .max(30)
      .step(0.1)
      .step(0.01)
      .onChange((ry) => {
        this.controls.target.y = ry
        this.camera.instance.updateWorldMatrix()
      })
    gui
      .add(this.camParams, 'cam_rz')
      .min(-20)
      .max(30)
      .step(0.1)
      .step(0.01)
      .onChange((rz) => {
        this.controls.target.z = rz
        this.camera.instance.updateWorldMatrix()
      })

    if (!displayGUI){
      gui.destroy()
    }
    /**
     * end
     */
  }

  moveCameraTo(goTo) {
    let cords = {
      x: this.camera.instance.position.x,
      y: this.camera.instance.position.y,
      z: this.camera.instance.position.z,
      rx: this.controls.target.x,
      ry: this.controls.target.y,
      rz: this.controls.target.z
    }

    new TWEEN.Tween(cords)
      .to({x: goTo.x, y: goTo.y, z: goTo.z, rx: goTo.rx, ry: goTo.ry, rz: goTo.rz})
      .onUpdate(() => {
          this.camera.instance.position.set(cords.x, cords.y, cords.z)
          this.controls.target.set(cords.rx, cords.ry, cords.rz)
        }
      )
      .start()
  }

  cameraLimit() {
    const intersects = this.raycaster.intersectObjects(this.scene.children)
    for (let i = 0; i < intersects.length; i++) {
      if (intersects[i].distance < 1) {
        this.camera.instance.position.y += 0.05
      }
    }
    const intersects2 = this.downRay.intersectObjects(this.scene.children)
    for (let i = 0; i < intersects2.length; i++) {
      if (intersects2[i].distance < 1) {
        this.camera.instance.position.y += 0.05
      }
    }
  }

  update() {
    this.cameraLimit()
    this.raycaster.setFromCamera(this.cursorPosition, this.camera.instance)
    this.downRay.setFromCamera(this.rayDirection, this.camera.instance)
    this.objectsToTest = []
    for (const marker of this.markers) {
      const obj = this.scene.children.find((child) => child.name === marker)
      if (obj) {
        this.objectsToTest.push(obj)
      }
    }
    const intersects = this.raycaster.intersectObjects(this.objectsToTest)
    if (intersects.length) {
      this.currentIntersect = intersects[0]
    } else {
      this.currentIntersect = null
    }
  }
}
