import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Grass {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.floor = null
    this.colorTexture = null
    this.ambientOcclusionTexture = null
    this.grassNormalTexture = null
    this.grassRoughnessTexture = null

    this.setTexture()
  }

  findFloor() {
    if(!this.floor) {
      this.floor = this.scene.children.find((child) => child.name === 'Plane030')
    }

  }
  
  setTexture() {
    this.colorTexture = this.resources.items.grassColorTexture
    this.ambientOcclusionTexture = this.resources.items.grassAmbientOcclusionTexture
    this.normalTexture = this.resources.items.grassNormalTexture
    this.roughnessTexture = this.resources.items.grassRoughnessTexture

    this.colorTexture.repeat.set(10, 10)
    this.ambientOcclusionTexture.repeat.set(10, 10)
    this.normalTexture.repeat.set(10, 10)
    this.roughnessTexture.repeat.set(10, 10)

    this.colorTexture.wrapS = THREE.RepeatWrapping
    this.ambientOcclusionTexture.wrapS = THREE.RepeatWrapping
    this.normalTexture.wrapS = THREE.RepeatWrapping
    this.roughnessTexture.wrapS = THREE.RepeatWrapping

    this.colorTexture.wrapT = THREE.RepeatWrapping
    this.ambientOcclusionTexture.wrapT = THREE.RepeatWrapping
    this.normalTexture.wrapT = THREE.RepeatWrapping
    this.roughnessTexture.wrapT = THREE.RepeatWrapping
  }

  setFloor() {
    this.findFloor()
    if (this.floor != null) {
      this.floor.material.map = this.colorTexture
      this.floor.material.aoMap = this.ambientOcclusionTexture
      this.floor.material.normalMap = this.normalTexture
      this.floor.material.roughnessMap = this.roughnessTexture
    }
  }

  update() {
    this.setFloor()
  }
}