import { Controller } from "@hotwired/stimulus"
import Experience from "../src/Experience.js"
import $ from 'jquery'

// Connects to data-controller="land-selection-modal"
export default class extends Controller {
  static targets = ["cornSoybeansSelection", "cornSoybeansWithBufferSelection", "cornSoybeansWithWetlandSelection", "perennialCoverSelection", "livestockSelection", "golfCourseSelection", "parkSelection", "housingSelection"]

  connect() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.boundReset = this.reset.bind(this)
    this.parcels = {}
    this.clickedParcelMarker = null

    document.getElementById('land-parcel-selection-modal-1').addEventListener('show.bs.modal', this.boundReset)
  }

  landUseRuralSelectionClick(e) {
    this.resetLandUseRuralSelection()
    if(this.isSelection(e)) {
      e.target.classList.toggle("selected")
      this.showLandUseRuralNextStep()
    }
  }

  landUseUrbanSelectionClick(e) {
    this.resetLandUseUrbanSelection()
    if(this.isSelection(e)) {
      e.target.classList.toggle("selected")
      this.showLandUseUrbanNextStep()
    }
  }

  urbanHousingDevelopmentClick(e) {
    this.resetUrbanHousingDevelopment()
    if(this.isSelection(e)) {
      e.target.classList.toggle("selected")
    }
  }

  fertilizeRateSelectionClick(e) {
    this.resetFertilizeRateSelection()
    if(this.isSelection(e)) {
      e.target.classList.toggle("selected")
      this.showFertilizerRateNextStep()
    }
  }

  grazingTypeClick(e) {
    this.resetGrazingTypeSelection()
    if(this.isSelection(e)) {
      e.target.classList.toggle("selected")
    }
  }

  tillageSelectionClick(e) {
    this.resetTillageSelection()
    if(this.isSelection(e)) {
      e.target.classList.toggle("selected")
      this.showTillageNextStep()
    }
  }

  fertilizeScheduleSelectionClick(e) {
    this.resetFertilizeSchedule()
    if(this.isSelection(e)) {
      e.target.classList.toggle("selected")
    }
  }

  applyChanges() {
    this.saveSelections()
    this.placeLandAsset()
    
  }
  
  isSelection(e) {
    return e.target.classList.contains("selection")
  }

  saveSelections() {
    const selectionsData = []
    const selections = document.getElementsByClassName("selected")
    for(const selection of selections) {
        selectionsData.push(selection.id)
    }
    this.parcels[this.clickedParcelMarker] = selectionsData
    console.log(this.parcels)
  }
  
  placeLandAsset() {
    // TODO: handle placing the land asset
    // if(this.clickedParcelMarker == "Parcel_Marker_1008") { //parcel marker 3
    // }
  
    // if(this.livestockSelectionTarget.classList.contains("selected")) {
    //   if(this.clickedParcelMarker == "Parcel_Marker_1010") { //parcel marker 4
    //     const parcel4Group = this.scene.children.find((child) => child.name === "Parcel_4_Livestock_Traditional_Grazing")
    //     const parcel4 = parcel4Group.children.find((child) => child.name === "Parcel_4_Livestock_Traditional")
    //     parcel4.visible = true
    //   }
    // }
  }

  showLandUseRuralNextStep() {
    // TODO: refactor
    if(this.cornSoybeansSelectionTarget.classList.contains("selected") || this.cornSoybeansWithBufferSelectionTarget.classList.contains("selected") || this.cornSoybeansWithWetlandSelectionTarget.classList.contains("selected")) {
      this.resetGrazingTypeSelection()
      $("#grazing-type").slideUp()
      $("#fertilizer-rate").slideDown()
    }

    if(this.livestockSelectionTarget.classList.contains("selected")) {
      this.resetFertilizeRateSelection()
      this.resetTillageSelection()
      this.resetFertilizeSchedule()
      $("#tillage").slideUp()
      $("#fertilizer-rate").slideUp()
      $("#fertilizer-schedule").slideUp()
      $("#grazing-type").slideDown()
    }

    if(this.perennialCoverSelectionTarget.classList.contains("selected")) {
      this.resetGrazingTypeSelection()
      this.resetFertilizeRateSelection()
      this.resetTillageSelection()
      this.resetFertilizeSchedule()
      $("#tillage").slideUp()
      $("#fertilizer-rate").slideUp()
      $("#grazing-type").slideUp()
      $("#fertilizer-schedule").slideUp()
    }
  }

  showLandUseUrbanNextStep() {
    // TODO: refactor
    if(this.housingSelectionTarget.classList.contains("selected")) {
      $("#urban-development-housing").slideDown()
    }
    else {
      this.resetLandUseRuralSelection()
      this.resetUrbanHousingDevelopment()
      $("#urban-development-housing").slideUp()
    }
  }

  showFertilizerRateNextStep() {
    if(this.cornSoybeansSelectionTarget.classList.contains("selected") || this.cornSoybeansWithBufferSelectionTarget.classList.contains("selected") || this.cornSoybeansWithWetlandSelectionTarget.classList.contains("selected")) {
      $("#tillage").slideDown()
    }
  }

  showTillageNextStep() {
    if(this.cornSoybeansSelectionTarget.classList.contains("selected") || this.cornSoybeansWithBufferSelectionTarget.classList.contains("selected") || this.cornSoybeansWithWetlandSelectionTarget.classList.contains("selected")) {
      $("#fertilizer-schedule").slideDown()
    }
  }

  resetLandUseRuralSelection() {
    this.resetSelected("land-use-rural")
  }

  resetLandUseUrbanSelection() {
    this.resetSelected("land-use-urban")
  }

  resetUrbanHousingDevelopment() {
    this.resetSelected("urban-housing-development")
  }

  resetFertilizeRateSelection() {
    this.resetSelected("rate")
  }

  resetTillageSelection() {
    this.resetSelected("tillage")
  }

  resetGrazingTypeSelection() {
    this.resetSelected("grazing")
  }

  resetFertilizeSchedule() {
    this.resetSelected("fertilizer-schedule")
  }

  resetSelected(className) {
    const selectionContainer = document.getElementsByClassName(className)[0]
    const selections = selectionContainer.getElementsByClassName("selected")
    for(const selection of selections) {
      selection.classList.remove("selected")
    }
  }

  reset() {
    this.resetLandUseRuralSelection()
    this.resetLandUseUrbanSelection()
    this.resetUrbanHousingDevelopment()
    this.resetFertilizeRateSelection()
    this.resetFertilizeSchedule()
    this.resetGrazingTypeSelection()
    this.resetTillageSelection()
    $("#grazing-type").hide()
    $("#fertilizer-schedule").hide()
    $("#fertilizer-rate").hide()
    $("#tillage").hide()
    $("#urban-development-housing").hide()

    this.clickedParcelMarker = document.getElementById("land-parcel-selection-modal-1").getAttribute("data-selected-parcel") //TODO: better way to get this?
    if(this.clickedParcelMarker === "Parcel_Marker_1020") {
      $("#land-use-rural").hide()
      $("#land-use-urban").show()
    }
    else {
      $("#land-use-urban").hide()
      $("#land-use-rural").show()
    }
  }
}