import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Sky {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources

    this.setTexture()
    this.setSky()
  }
  
  setTexture() {
    this.texture = this.resources.items.skyTexture
  }

  setSky() {
    this.scene.background = this.texture
  }

  update() {
    this.setSky()
  }
}