import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Stream {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
  }

  findStream() {
    if(!this.stream) {
      this.stream = this.scene.children.find((child) => child.name === 'Water_Substance')
    }
  }

  setReflection() {
    this.findStream()
    if (this.stream != null) {
      this.stream.material.envMap = this.resources.items.skyTexture
    }
  }
  
  update() {
    this.setReflection()
  }
}